import React from 'react'
import styled from 'styled-components'
import Container from 'components/Container'
import Download from 'components/Download'
import Seo from 'components/Seo'
import WhitePaperImg from 'images/white-paper.png'
import WhitePaper from '../assets/white-paper.pdf'
import { sizes } from 'styles'

const WhitePapersPage = styled.div`
  //Just to override any other styling from previous global styles
  * {
    font-family: 'Helvetica Now Text';
  }
  position: relative;
`
const Header = styled.h1`
  display: flex;
  flex-direction: column;

  span:first-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #5656c2;
    letter-spacing: 0.3em;
  }

  span:last-child {
    line-height: 40px;
    font-size: 32px;
    font-weight: 700;
    color: #273c49;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #273c49;
  margin-top: 40px;

  @media ${sizes.desktop} {
    flex-direction: row;
    gap: 100px;
  }
`
const StyledImage = styled.img`
  height: auto;
  object-fit: contain;
  max-width: 500px;

  @media ${sizes.desktop} {
    max-width: 600px;
  }
  @media ${sizes.largeDesktop} {
    max-width: 700px;
  }
`

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;
`

const Content = styled.div`
  @media ${sizes.tabletPortrait} {
    max-width: 500px;
  }
`

const WhitePapers = () => {
  return (
    <WhitePapersPage>
      <Container>
        <Seo
          title="Building Remotely - Remote Retention White Paper"
          description={'Download white paper about remote retention.'}
          image={{
            src: '//buildingremotely.com/white-paper-meta.png',
          }}
        />
        <Header>
          <span>WHITE PAPER</span>
          <span>Employee Retention</span>
        </Header>
        <Wrapper>
          <StyledImage src={WhitePaperImg} alt="White paper" />
          <Content>
            <p>
              SafetyWing performed a global survey exploring employee attitudes
              towards remote work. Companies can use this information to
              optimize their recruitment and retention strategies.
            </p>
            <p>In this white paper, you’ll find out: </p>
            <ul>
              <li>What motivates remote workers to perform at their best</li>
              <li>
                The biggest challenges employees face while working remotely
              </li>
              <li>What influences employees to stay at or leave a job</li>
              <li>How poor retention can cost the employer long-term</li>
            </ul>
            <DownloadWrapper>
              <Download file={WhitePaper} />
            </DownloadWrapper>
          </Content>
        </Wrapper>
      </Container>
    </WhitePapersPage>
  )
}

export default WhitePapers
