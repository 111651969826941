import React, { useState } from 'react'

import { emailIsValid, downloadFile } from 'src/helpers'
import { Button, Disclaimer, Header, Input, Wrapper } from './styled'
import { pingZappier } from 'src/api'
import { addEmail } from 'src/airtable'

const Download = ({ file, paperName = 'Employee Retention 2022' }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const isValid = () =>
    firstName.length >= 2 && lastName.length >= 2 && emailIsValid(email)

  const emptyFields = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  return (
    <Wrapper>
      <Header>Download the white paper</Header>
      <Input
        value={firstName}
        onChange={({ target }) => setFirstName(target.value)}
        placeholder="First name"
      />
      <Input
        value={lastName}
        onChange={({ target }) => setLastName(target.value)}
        placeholder="Last name"
      />
      <Input
        value={email}
        onChange={({ target }) => setEmail(target.value)}
        placeholder="Work email"
      />
      <Button
        onClick={() => {
          if (isValid()) {
            addEmail(firstName, lastName, email, paperName)
            pingZappier(firstName, lastName, email, paperName)
            downloadFile(file, `${paperName} - White paper by SafetyWing`)
            emptyFields()
          }
        }}
        disabled={!isValid()}
      >
        Download
      </Button>
      <Disclaimer>
        By downloading the white paper you are agreeing to receive emails from
        SafetyWing and Building Remotely.
      </Disclaimer>
    </Wrapper>
  )
}

export default Download
