import styled from 'styled-components'
import { sizes } from 'styles'

export const Wrapper = styled.div`
  width: 100%;
  background: #e1e1f4;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  margin-top: 5%;
  gap: 16px;

  @media ${sizes.tabletPortrait} {
    max-width: 310px;
  }
`
export const Header = styled.h2`
  line-height: 24px;
  font-size: 20px;
  margin-top: 0;
`

export const Button = styled.button`
  width: 100%;
  border: none;
  outline: none;
  background: #7addb6;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 12px 24px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  margin-top: 16px;

  :hover {
    background: #abe9d1;
  }

  :disabled {
    background: #a9b1b6;
  }
`

export const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  background: #f4f5f6;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 16px 20px 16px 12px;
`

export const Disclaimer = styled.p`
  font-size: 12px;
  text-align: center;
  color: #7d8a92;
  margin: 0;
  padding: 0 12px;
  line-height: 12px;
`
