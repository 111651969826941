const Airtable = require('airtable')

const base = new Airtable({ apiKey: process.env.GATSBY_AIRTABLE_API_KEY }).base(
  process.env.GATSBY_AIRTABLE_BASE_ID
)

export const addEmail = (firstName, lastName, email, fileName = '') => {
  base(process.env.GATSBY_AIRTABLE_TABLE_NAME).create([
    {
      fields: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        fileName: fileName,
        date: `${new Date().getMonth()}/${new Date().getDate()}/${new Date().getFullYear()}`,
      },
    },
  ])
}
